import 'babel-polyfill';
import isEmpty from 'lodash/isEmpty';
const { dynamicImport } = require("./forms/utils/dynamicImport");

(function () {
  if (document.readyState === 'complete') {
    whenFormSettingsAreReady(initApp);
  } else {
    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        whenFormSettingsAreReady(initApp);
      }
    };
  }
})();

function whenFormSettingsAreReady(callback) {
  const configLoadInterval = window.setInterval(intervalAction, 1);

  function intervalAction() {
    if (!isEmpty(window.webformSettings) && !window.formsCoreInitialised) {
      window.formsCoreInitialised = true;
      callback(window.webformSettings);
      window.clearInterval(configLoadInterval);
    }
  }
}

async function initApp(settings) {
  const { server } = settings;
  const serverEnv = server.environment;
  const originId = server.origin_id;

  await dynamicImport({ moduleName: 'webformVendor', serverEnv, originId });
  const run = await dynamicImport({ moduleName: 'webformFull', serverEnv, originId });
  run?.default();
}

