export const createDynamicImportUrl = ({ moduleName, serverEnv, originId, path }) => {
  if (path) {
    return `${path}/${moduleName}.js`;
  }

  if (serverEnv === 'staging') {
    let stagingLetter = window.location.host.split('.')[0].split('-')[1];

    if (stagingLetter === undefined) {
      stagingLetter = 'a';
    }

    return `https://images.webforms.mvfglobal.com/js/staging_${stagingLetter}-${moduleName}.js`;
  }

  if (serverEnv === 'local') {
    return `http://localhost:9777/js/${moduleName}.js`;
  }

  return `https://images.webforms.mvfglobal.com/js/production_${originId.includes('-eu') ? 'europe' : 'canada'}-${moduleName}.js`;
};

export const getModule = async url => new Promise((fulfil) => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.src = url;
  script.onload = () => {
    fulfil(window.lib);
    delete window.lib;
  };

  document.head.appendChild(script);
});

const getFormModule = name => window.formModules[name];
const setFormModule = (name, module) => window.formModules[name] = module;
const getFormModuleStatus = name => window.pendingFormModules[name];
const setFormModuleStatus = (name, status) => window.pendingFormModules[name] = status;

const setupModuleCache = () => {
  if (!window.pendingFormModules) {
    window.pendingFormModules = {};
  }

  if (!window.formModules) {
    window.formModules = {};
  }
}

export const isDynamicImportBusy = (moduleName) => {
  setupModuleCache();
  return getFormModuleStatus(moduleName) === 'PENDING';
}

export const dynamicImport = async ({ moduleName, serverEnv, originId, path }) => {
  setupModuleCache();

  if (getFormModule(moduleName) || getFormModuleStatus(moduleName) === 'COMPLETE') {
    setFormModuleStatus(moduleName, 'COMPLETE');
    return getFormModule(moduleName);
  }

  if (
    !(getFormModuleStatus(moduleName) === 'PENDING' ||
    getFormModuleStatus(moduleName) === 'COMPLETE')
  ) {
    setFormModuleStatus(moduleName, 'PENDING');
    const module = await getModule(
      createDynamicImportUrl({
        moduleName,
        serverEnv,
        originId,
        path,
      })
    );

  setFormModule(moduleName, module);
  setFormModuleStatus(moduleName, 'COMPLETE');
  return module;
  }

};
